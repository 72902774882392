export const ArtImageMap = () => import('../../components/art-image-map.vue' /* webpackChunkName: "components/art-image-map" */).then(c => wrapFunctional(c.default || c))
export const ArtImage = () => import('../../components/art-image.vue' /* webpackChunkName: "components/art-image" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const Buy = () => import('../../components/buy.vue' /* webpackChunkName: "components/buy" */).then(c => wrapFunctional(c.default || c))
export const ContractSatus = () => import('../../components/contract-satus.vue' /* webpackChunkName: "components/contract-satus" */).then(c => wrapFunctional(c.default || c))
export const Counters = () => import('../../components/counters.vue' /* webpackChunkName: "components/counters" */).then(c => wrapFunctional(c.default || c))
export const FullScreenImage = () => import('../../components/full-screen-image.vue' /* webpackChunkName: "components/full-screen-image" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../components/header-menu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderSmallMenu = () => import('../../components/header-small-menu.vue' /* webpackChunkName: "components/header-small-menu" */).then(c => wrapFunctional(c.default || c))
export const InfoTooltip = () => import('../../components/info-tooltip.vue' /* webpackChunkName: "components/info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const NumberInput = () => import('../../components/number-input.vue' /* webpackChunkName: "components/number-input" */).then(c => wrapFunctional(c.default || c))
export const PreviewImageTooltip = () => import('../../components/preview-image-tooltip.vue' /* webpackChunkName: "components/preview-image-tooltip" */).then(c => wrapFunctional(c.default || c))
export const PreviewImage = () => import('../../components/preview-image.vue' /* webpackChunkName: "components/preview-image" */).then(c => wrapFunctional(c.default || c))
export const SectionFooter = () => import('../../components/section-footer.vue' /* webpackChunkName: "components/section-footer" */).then(c => wrapFunctional(c.default || c))
export const SectionHeader = () => import('../../components/section-header.vue' /* webpackChunkName: "components/section-header" */).then(c => wrapFunctional(c.default || c))
export const Tip = () => import('../../components/tip.vue' /* webpackChunkName: "components/tip" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerPopup = () => import('../../components/video-player-popup.vue' /* webpackChunkName: "components/video-player-popup" */).then(c => wrapFunctional(c.default || c))
export const Wallet = () => import('../../components/wallet.vue' /* webpackChunkName: "components/wallet" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
