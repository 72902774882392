import config from "@/config";

export default ({ app }, inject) => {
    let socket = new Socket(app);
    inject('WS', socket);
};

class Socket {
    constructor(app) {
        this.app = app;
        this.open = false;
        this.connected = false;
        this.interval = null;
        
        this.connect();
        return {
            send: msg => this.socket.send(msg),
            status: () => this.connected
        };
    }

    connect() {
        if (this.connected) return;

        this.socket = new WebSocket(config.wssUrl);

        this.socket.addEventListener('open', event => {
            this.open = true;
            this.connected = true;
            this.interval = setInterval(() => {
                this.socket.send(JSON.stringify({ type: 'ping' }));
            }, 1000 * 30);
            
            this.afterOpen(this.app.router.currentRoute.name);
        });
        this.socket.addEventListener('message', event => {
            this.parseMessages(JSON.parse(event.data));
        });
        this.socket.addEventListener('error', event => {
            this.connected = false;
        });
        this.socket.addEventListener('close', event => {
            this.connected = false;
            this.open = false;
            clearInterval(this.interval);
            setTimeout(() => this.connect(), 5000);
        });
    }

    afterOpen(name){
        //console.log(name);
        switch(name){
            case "index___en":
            case "about___en":
            case "how-it-works___en":
            this.socket.send(
                JSON.stringify({
                    type: 'getBuingStatus'
                })
            );
            break;
            case "preview-details-id___en":
            case "after-market___en":
            this.socket.send(
                JSON.stringify({
                    type: 'getAfterMarketStatus'
                })
            );
            break;
            case "preview-address___en":
            case "rewards___en":
            this.socket.send(
                JSON.stringify({
                    type: 'getRewardsStatus'
                })
            );
            break;
        }
    }

    parseMessages(d){
        switch (d.type) {
            case 'buingStatus':
            delete d.type;
            d.phases = d.buyLog.map((a, i) => {
                return {
                    buyers: a.b.map(b => d.indexes[b]),
                    ids: a.i,
                    phase: i,
                    prize: a.p,
                    show: a.b.map(() => true),
                    showItems: 5,
                    winners: i < d.buyWinners.length ? d.buyWinners[i].i : [],
                    winAmount: a.w
                };
            });
            this.app.store.commit('token/set', d);
            break;
            case 'TokenPurchased':
            if (typeof d.indexes != 'undefined') {
                this.app.store.commit('token/addIndexes', d.indexes);
            }
            this.app.store.commit('token/updatePhase', d);
            break;
            case 'DrawLots':
            this.app.store.commit('token/updateBuyWinners', d);
            break;
            case 'afterMarketStatus':
            delete d.type;
            this.app.store.commit('token/set', d);
            break;
            case 'PricesUpdated':
            this.app.store.commit('token/updateOwning', d);
            break;
            case 'TokensSold':
            delete d.type;
            this.app.store.commit('token/setOwning', d);
            break;
            case 'basicInfo':
            delete d.type;
            this.app.store.commit('token/set', d);
            break;
            case 'getRewardsStatus':
            delete d.type;
            this.app.store.commit('token/set', d);
            case 'imageUpdate':
            delete d.type;
            this.app.store.commit('token/updateImage', d);
            break;
            case 'updateBlockedTokens':
            this.app.store.commit('token/setBlockedTokens', d.blockedTokens);
            break;
            case 'updateWinHodlers':
            delete d.type;
            this.app.store.commit('token/setWinHodlers', d);
            break;
            case "VIPFounded": 
            delete d.type;
            this.app.store.commit('token/set', d);
            break;
        }
        this.app.store.commit('token/setLoading', false);
    }
}
