//import web3 from "web3";
import Web3ModalVue from "web3modal-vue";
import config from "@/config";
var web3Modal = null;
var library = null;
let appInstance = null;
const ABI = require("./../assets/DecentralArt.json");
const ABI223 = require("./../assets/ERC223ART.json");
const contractAddress = config.contract;
const erc223Address = config.erc223;

const infuraId = "90952d0af11c4bccbcee97c79100061d";
  const MewConnect = require("@myetherwallet/mewconnect-web-client");
  const WalletConnectProvider = require("@walletconnect/web3-provider");
  const ProviderOptions = {
    walletconnect: {
      package: WalletConnectProvider.default,
      options: 
{       infuraId: infuraId,
        apiKey: '14cbc8df17198727fd10df3f7dc0c997',
        rpc: {
          1337: "https://decentral-art.com/wss",
        },
      },
    },
    mewconnect: { 
    package: MewConnect.default, // required
    options: {
      infuraId: infuraId, // required
      network: "mainnet",
      rpc: "wss://mainnet.infura.io/ws/v3/90952d0af11c4bccbcee97c79100061d",
    },
  },
};

const Contract = require("web3-eth-contract");
let myContract = null, erc223Contract = null;

const parseEvent = function (error, event) {};

const registerContract = () => {
  if (!myContract) {
    Contract.setProvider(library.provider);
    myContract = new Contract(ABI, contractAddress);
    erc223Contract = new Contract(ABI223, erc223Address);
    /*myContract.events.allEvents({}, parseEvent);
    myContract.getPastEvents('allEvents', {
      fromBlock: 0,
      topics: [
        "0x534f1d8d2c3b462b484917bfaed9564a69bfc7f31710cd9f5071d8881d5c78d0", // ProposalCreated
      ]
    }, (error, events) => console.log(error,events));*/
  }
};

const Call = function (erc223, method, ...params) {
  registerContract();
  let callParams = {},
    account = appInstance.store.state.web3.account;
  if (account != "") {
    callParams = { from: account };
  }
  //console.log(erc223, erc223Contract);
  if(erc223)
    return erc223Contract.methods[method](...params).call(callParams);
  else
    return myContract.methods[method](...params).call(callParams);
};

const Send = function (erc223, method, sendParams, ...params) {
  registerContract();
  let callParams = {},
    account = appInstance.store.state.web3.account;
  if (account != "") {
    sendParams = Object.assign(sendParams, { from: account });
  }
  if(erc223)
    return erc223Contract.methods[method](...params).send(sendParams);
  else 
    return myContract.methods[method](...params).send(sendParams);
};

/*const Blockchain = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$web3Call = Call;
    Vue.prototype.$web3Send = Send;
    Vue.prototype.$web3ProviderOptions = ProviderOptions;
    Vue.mixin({
      beforeCreate() {
        appInstance = this;
      }
    });
  },  
  call: Call,
  send: Send
};

export default Blockchain;*/

const EtherscanUrl = (hash, type = "tx") => {
  switch(type){
    case "address": return "https://etherscan.io/address/" + hash;
    case "internal": return "https://etherscan.io/tx/" + hash + "#internal";
    default: return "https://etherscan.io/tx/" + hash;
  }
} 

export default ({ app }, inject) => {
  appInstance = app;
  inject('etherscanUrl', EtherscanUrl);
  inject('web3Call', Call);
  inject('web3Send', Send);
  inject('setWeb3Modal', (ref) => web3Modal = ref);
  inject('web3Modal', () => web3Modal);
  inject('setLibrary', (ref) => library = ref);
  inject('library', () => library);
  inject('web3ProviderOptions', ProviderOptions);
}