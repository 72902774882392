import { numberWithSpaces } from '@/helpers/numberWithSpaces';
import { shortAddress } from '@/helpers/shortAddress';
import { plainString } from '@/helpers/plainString';
import tinycolor from 'tinycolor2';
import config from '@/config';

export default ({ app }, inject) => {
    const globalFn = {
        toUSD: numberWithSpaces,
        shortAddress,
        plainString,
        tinycolor
    };

    inject('globalFn', globalFn);
};
