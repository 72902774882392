//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from "@/config";
export default {
  name: 'default',

  data() {
    return {
      logged: config.production,
      passw: '',
      mounted:false
    };
  },

  methods: {
    keydownEvent(e) {
      if (e.which === 13) {
        this.login();
      }
    },

    login() {
      if (this.passw == config.password) {
        localStorage.setItem('auth', true);
        this.logged = true;
      }
    }
  },

  beforeMount() {
    if (localStorage.getItem('auth')) this.logged = true;
  },

  mounted() {
    this.mounted = true;
  },

};
