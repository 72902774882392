import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _00c8fa86 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _f8347166 = () => interopDefault(import('../pages/after-market.vue' /* webpackChunkName: "pages/after-market" */))
const _1f535d82 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _47149f3d = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _85262658 = () => interopDefault(import('../pages/rewards.vue' /* webpackChunkName: "pages/rewards" */))
const _5cd30c20 = () => interopDefault(import('../pages/preview/main.vue' /* webpackChunkName: "pages/preview/main" */))
const _1b26f554 = () => interopDefault(import('../pages/preview/details/_id.vue' /* webpackChunkName: "pages/preview/details/_id" */))
const _7c55ab9c = () => interopDefault(import('../pages/preview/_address.vue' /* webpackChunkName: "pages/preview/_address" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _00c8fa86,
    name: "about___en"
  }, {
    path: "/after-market",
    component: _f8347166,
    name: "after-market___en"
  }, {
    path: "/es",
    component: _1f535d82,
    name: "index___es"
  }, {
    path: "/how-it-works",
    component: _47149f3d,
    name: "how-it-works___en"
  }, {
    path: "/rewards",
    component: _85262658,
    name: "rewards___en"
  }, {
    path: "/es/about",
    component: _00c8fa86,
    name: "about___es"
  }, {
    path: "/es/after-market",
    component: _f8347166,
    name: "after-market___es"
  }, {
    path: "/es/how-it-works",
    component: _47149f3d,
    name: "how-it-works___es"
  }, {
    path: "/es/rewards",
    component: _85262658,
    name: "rewards___es"
  }, {
    path: "/preview/main",
    component: _5cd30c20,
    name: "preview-main___en"
  }, {
    path: "/es/preview/main",
    component: _5cd30c20,
    name: "preview-main___es"
  }, {
    path: "/es/preview/details/:id?",
    component: _1b26f554,
    name: "preview-details-id___es"
  }, {
    path: "/es/preview/:address?",
    component: _7c55ab9c,
    name: "preview-address___es"
  }, {
    path: "/preview/details/:id?",
    component: _1b26f554,
    name: "preview-details-id___en"
  }, {
    path: "/preview/:address?",
    component: _7c55ab9c,
    name: "preview-address___en"
  }, {
    path: "/",
    component: _1f535d82,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
