import { ethers } from "ethers";
var waitingForClear = false;
var waitingForConnect = false;
export const state = () => ({
    library: null, // getLibrary(),
    active: false,
    account: null,
    balance: 0,
    chainId: 0,
  })
export const mutations = {
  initWeb3Modal(state) {
    if (this.$web3Modal().cachedProvider) {
      this.dispatch("web3/connect");
    }

      this.$web3Modal().providerController.on("connect", (provider) => {
        if (state.active === false) {
          this.dispatch("web3/connect");
        }
      });
  },
  setLibrary(state, library) {
    state.library = library;
  },
  setActive(state, active) {
    state.active = active;
  },
  setAccount(state, account) {
    state.account = account == null? null : account.toLowerCase();
    if (account == null) {
      state.balance = "";
    }
  },
  setBalance(state, balance) {
    state.balance = balance;
  },
  setChainId(state, chainId) {
    state.chainId = chainId;
  },
  setContractInfo(state, data){
    state.contractBalance = parseFloat(data.TotalWei);
    state.mainGoalBalance = parseFloat(data.MainGoalWei);
  },
}

export const getters = {
}


export const actions = {
  async connect({ state, commit, dispatch }) {
    if(state.active || waitingForConnect) return;

    try{
      waitingForConnect = true;
      const provider = await this.$web3Modal().connect();
      const library = new ethers.providers.Web3Provider(provider);
      library.pollingInterval = 12000;
      this.$setLibrary(library);

      const accounts = await library.listAccounts();
      //console.log("accounts", accounts);
      if (accounts.length > 0) {
        commit("setAccount", accounts[0]);
        //let balance = await library.getBalance(accounts[0]);
        //balance = (parseFloat(balance.toString()) / 10 ** 18).toFixed(4);
        //commit("setBalance", balance);
      }
      const network = await library.getNetwork();
      commit("setChainId", network.chainId);
      commit("setActive", true);
      provider.on("disconnected", (info) => {
        dispatch("resetApp")
      });

      provider.on("disconnect", (code, reason) => {
        dispatch("resetApp")
      });

      provider.on("connect", async (info) => {
        let chainId = parseFloat(info.chainId);
        commit("setChainId", chainId);
      });

      provider.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          commit("setAccount", accounts[0]);
          let balance = await library.getBalance(accounts[0]);
          balance = (parseFloat(balance.toString()) / 10 ** 18).toFixed(4);
          commit("setBalance", balance);
        } else {
          await dispatch("resetApp");
        }
      });
      provider.on("chainChanged", async (chainId) => {
        chainId = parseFloat(chainId);
        commit("setChainId", chainId);
      });
    } catch(e) {
      await dispatch("resetApp");
      return;
    }
  },

  async resetApp({ state, commit }) {
    //console.log("resetApp", this.$web3Modal().cachedProvider);
    try {
      this.$web3Modal().disconnect();
      waitingForConnect = false;
      if(this.$web3Modal().cachedProvider){
        waitingForClear = true;
        await this.$web3Modal().clearCachedProvider();
        waitingForClear = false;
      }
    } catch (error) {
      waitingForClear = false;
      console.error(error);
    }
    
    commit("setAccount", null);
    commit("setActive", false);

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  },

  async getProprosals(state, params) {
    const proprosals = [];
    const contractState = await blockchain.call("info");
    this.commit("setContractInfo", contractState);
    const proposalsLength = await blockchain.call("proposalsLength");

  },
}