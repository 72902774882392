import Vue from 'vue';

export const state = () => ({
  myAddress: "",
  actualPhase: 0,
  mainGoalWei: 0,
  buyLog: [],
  buyWinners:  [],
  ethPrice: 0,
  holdings: [],
  indexes: [],
  lastTokenIndex: -1,
  totalBuyedPrice: 0,
  totalCollectorsAmount: 0,
  totalWinners: 0,
  totalRwards: 0,
  phases: [],
  loading: true,
  owning: [],
  vipIndexes: [],
  percentWinners: {},
  image: "https://decentral-art.com/big-image.jpg?t="+Date.now(),
  blockedTokens: [],
})

const computeCollectorsAmount = (state) => {
  let amount = 0;
  Object.keys(state.percentWinners).map((w)=>{
    amount += state.percentWinners[w].amount;
  });
  state.totalCollectorsAmount = amount;
  return state;
};

export const getters = {
  myAddressIndex: (state, getters, rootState) => {
    if(!rootState.web3.active)
      return -1;
    else
      return state.indexes.indexOf(rootState.web3.account);
  },
  myTokens: (state, getters, rootState) => {
    let i = getters["myAddressIndex"];
    if(i == -1 || state.owning.length <= i){
      return 0;
    } else {
      return Object.keys(state.owning[i]).length;
    }
  },
  forSell: state => {
    let toSell = {};
    state.owning.map((u) => {
      Object.keys(u).map((k) => {
        if(u[k] > 0)
          toSell[k] = u[k];
      });
    })
    return toSell;
  },
  holdingsAddresses: state => {
    let unique = [];
    state.holdings.map(phase => {
      unique.push(...phase.winners);
    });
    unique = unique.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    return unique.length;
  },
  holdingsPayed: state => {
    let sum = 0;
    state.holdings.map(phase => {
      sum += phase.winners.length * phase.amount;
    });
    return sum;
  },
  indexesToSell: (state,getters) => {
    return Object.keys(getters.forSell).map(parseFloat);
  }
}

export const mutations = {
  set(state, data) {
    state = Object.assign(state, data);
    state = computeCollectorsAmount(state);
  },
  setBlockedTokens(state, blockedTokens) {
    state.blockedTokens = blockedTokens;
  },
  setWinHodlers(state, data){
    if(state.holdings.length == data.index){
      state.holdings.push(data);
    }
  },
  setOwning(state, data){
    state.mainGoalWei = data.mainGoalWei;    
    for (var i = 0; i < data.sellersIds.length; i++) {
      Vue.set(state.owning, data.sellersIds[i], data.owning[i]);
    }
    Vue.set(state.owning, data.buyerId, data.buyOwning);
    state.blockedTokens = data.blockedTokens;
    if(typeof data.indexes != "undefined" && state.indexes.indexOf(data.indexes) == -1){
      state.indexes.push(data.indexes);
    }
  },
  addIndexes(state, index) {
    state.indexes.push(index);
  },
  setLoading(state, loading){
    state.loading = loading;
  },
  updatePhase(state, data) {
    state = Object.assign(state, {
      actualPhase: data.actualPhase,
      lastTokenIndex: data.lastTokenIndex,
      mainGoalWei: data.mainGoalWei,
      totalBuyedPrice: data.totalBuyedPrice
    });
    Vue.set(state.buyLog, data.updatingPhase, data.buyLog);
    Vue.set(state.phases, data.updatingPhase, {
      buyers: data.buyLog.b.map((b)=>state.indexes[b]),
      ids: data.buyLog.i,
      phase: data.updatingPhase,
      prize: data.buyLog.p,
      show: data.buyLog.b.map(()=>true),
      showItems: state.phases[data.updatingPhase].showItems,
      winners: state.phases[data.updatingPhase].winners,
      winAmount: state.phases[data.updatingPhase].winAmount
    });
  },
  updateBuyWinners(state, data){
    state.totalWinners = data.totalWinners;
    state.totalRwards = data.totalRwards;
    if(state.buyWinners.length == data.phase){
      state.buyWinners.push(data.buyWinners);
      Object.assign(state.phases[data.phase], {winners: data.buyWinners.i}); 
    }

    if(typeof data.indexes != "undefined" && state.indexes.indexOf(data.indexes) == -1){
      state.indexes.push(data.indexes);
    }
  },
  showMoreBuyed(state, phase){
    state.phases[phase].showItems += 5;
  },
  updateOwning(state, owning) {
    state.blockedTokens = owning.blockedTokens;
    Vue.set(state.owning, owning.ownerIndex, owning.data);
  },
  updateImage(state, data) {
    state.image = "https://decentral-art.com/big-image.jpg?t="+Date.now();
    state = Object.assign(state, data);
    state = computeCollectorsAmount(state);
  }
}